import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Note: You can change "images" to whatever you'd like.

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
          edges {
            node {
              relativePath
              name
              absolutePath
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.absolutePath.includes(props.filename);
      });
      if (!image) {
        return null
      }

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return <Img style={{width:`100%`, height:`100%`, position: `absolute`, zIndex: 0}} alt={props.alt} fluid={image.node.childImageSharp.fluid}/>
    }}
  />
)

export default Image;